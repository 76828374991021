import { ColumnsType } from 'antd/es/table';
import { Button, Space, Divider } from 'antd';

import { ManagerStatus } from '@enums/managers.enum';
import { MainTableRecordType } from './interfaces';

interface CreateTableColumnsOptions {
  onEditClick: (mgrId: string) => void;
  onReinviteClick: (mgrId: string) => void;
}

const ManagerStatusNaming = {
  [ManagerStatus.INVITED]: 'Invited',
  [ManagerStatus.INVITATION_FAILED]: 'Invitation Failed',
  [ManagerStatus.ACTIVATED]: 'Activated',
  [ManagerStatus.DEACTIVATED]: 'Deactivated',
  [ManagerStatus.DELETED]: 'Delete',
  [ManagerStatus.FLUSHED_TOKEN]: 'Flushed Token',
};

const renderManagerStatus = (status: ManagerStatus) => {
  return ManagerStatusNaming[status];
};

export const createMainTableColumns = (
  options: CreateTableColumnsOptions,
): ColumnsType<MainTableRecordType> => {
  const { onEditClick, onReinviteClick } = options;
  return [
    { title: 'ID', dataIndex: 'id' },
    { title: 'Name', dataIndex: 'name' },
    { title: 'Email', dataIndex: 'email' },
    { title: 'Phone', dataIndex: 'phone' },
    {
      title: 'Status',
      dataIndex: 'status',
      render(status) {
        return renderManagerStatus(status);
      },
    },
    {
      title: 'Default Organization',
      dataIndex: ['defaultManagerOrganization', 'organization', 'name'],
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      render: (_, record) => (
        <Space split={<Divider type="vertical" />}>
          <Button type="link" onClick={() => onEditClick(record.id)}>
            Edit
          </Button>
          <Button
            type="link"
            disabled={
              ![
                ManagerStatus.INVITED,
                ManagerStatus.INVITATION_FAILED,
              ].includes(record.status)
            }
            onClick={() => onReinviteClick(record.id)}
          >
            Reinvite
          </Button>
        </Space>
      ),
    },
  ];
};
