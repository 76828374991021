import { stringify } from 'qs';

import { Utils, Managers } from '@interfaces/apis';
import { formatResponse, formatResponseWithMeta } from '@utils/api.util';
import { AuthIdentity } from '@enums/auth-identity.enum';
import { tokenStore } from '@stores';
import { superAdminAxios, managerAxios } from './axios';

export const getManagerMeOrgs = async () =>
  managerAxios
    .get<Utils.WeMoReplyMany<Managers.MeOrganization>>(
      'managers/me/organizations',
    )
    .then(formatResponse);

export const getManyManagerMeOrgPermission = async (
  orgId: Managers.MeOrganization['id'],
  query?: Managers.FindManyMeOrgPermissionQuery,
) =>
  managerAxios
    .get<Utils.WeMoReplyMany<Managers.MeOrgPermission>>(
      `managers/me/organizations/${orgId}/permissions`,
      { params: query },
    )
    .then(formatResponse);

export const getManagerCheckMeOneOrgPerm = async (
  orgId: Managers.MeOrganization['id'],
  name: Managers.MeOrgPermission['name'],
) =>
  managerAxios.get(`managers/me/organizations/${orgId}/permissions/check`, {
    params: { name },
  });

export const getManagerMeBelongToOneOrg = async (
  orgId: Managers.MeOrganization['id'],
) => managerAxios.get(`managers/me/belong-to/organizations/${orgId}`);

export const getManagerMeInfo = async () =>
  managerAxios
    .get<Utils.WeMoReply<Managers.MeManager>>(`managers/me/info`)
    .then(formatResponse);

export const getManagers = async (
  query?: Utils.RequiredPagination<Managers.FindManyManagerQuery>,
) =>
  superAdminAxios
    .get<Utils.WeMoReplyWithMeta<Managers.Manager>>(
      `managers?${stringify(query)}`,
    )
    .then(formatResponseWithMeta);

export const getOneManager = async (mgrId: string) =>
  superAdminAxios
    .get<Utils.WeMoReply<Managers.Manager>>(`managers/${mgrId}`)
    .then(formatResponse);

export const postOneManager = async (data: Managers.CreateOneManager) =>
  superAdminAxios
    .post<Utils.WeMoReply<Managers.Manager['id']>>('managers', data)
    .then(formatResponse);

export const patchOneManager = async (
  mgrId: string,
  data: Managers.UpdateOneManager,
) => superAdminAxios.patch(`managers/${mgrId}`, data);

export const getManyManagerByOrg = async (
  orgId: string,
  query?: Utils.RequiredPagination<Managers.FindManyManagerQuery>,
) =>
  managerAxios
    .get<Utils.WeMoReplyWithMeta<Managers.ManagerByOrg>>(
      `managers/organizations/${orgId}?${stringify(query)}`,
    )
    .then(formatResponseWithMeta);

export const getOneManagerByOrg = async (mgrId: string, orgId: string) =>
  managerAxios
    .get<Utils.WeMoReply<Managers.OneManagerByOrg>>(
      `managers/${mgrId}/organizations/${orgId}`,
    )
    .then(formatResponse);

export const patchOneManagerByOrg = async (
  mgrId: string,
  orgId: string,
  data: Managers.UpdateOneManagerByOrg,
) => managerAxios.patch(`managers/${mgrId}/organizations/${orgId}`, data);

export const patchOneManagerMeOrgNow = async (orgId: string) => {
  const { accessToken, refreshToken } = await managerAxios
    .patch<Utils.WeMoReply<Managers.ManagerMeToken>>(
      `managers/me/organizations/${orgId}/now`,
    )
    .then(formatResponse);

  tokenStore
    .getState()
    .setToken(AuthIdentity.MANAGER, accessToken, refreshToken);
};

export const reinviteOne = async (mgrId: string) =>
  superAdminAxios.post(`managers/reinvite`, { id: mgrId });
